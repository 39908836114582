<template>
  <component
    v-on="$listeners"
    :is="link ? SfLink : 'div'"
    :link="link"
    class="ami-i"
    data-transaction-name="Menu - Go To Link"
  >
    <div
      v-if="item.icon"
      class="ami-i__icon"
      v-html="item.icon"
    />
    <div
      v-else-if="item.fontIcon"
      class="ami-i__icon"
    >
      <span
        class="ami-i__icon-font"
        :style="`--menu-font-icon: var(${item.fontIcon}); --menu-font-icon-size: ${item.fontSize || 20}px;`"
      />
    </div>

    <span
      class="ami-i__label"
    >
      {{ $t(item.name) }}
    </span>

    <span
      v-if="item.comment"
      class="ami-i__comment"
    >
      {{ item.comment }}
    </span>

    <div v-if="showChevron" class="m-menu__dropdown">
      <SfChevron />
    </div>
  </component>
</template>

<script>
import { defineComponent } from 'vue';
import { SfLink, SfChevron } from '@storefront-ui/vue';

export default defineComponent({
  name: 'AMenuItem',
  components: {
    SfChevron
  },
  props: {
    showChevron: {
      type: Boolean,
      default: false
    },
    link: {
      type: String,
      default: () => null
    },
    item: {
      type: Object,
      required: true
    }
  },
  setup () {
    return {
      SfLink
    }
  }
});
</script>

<style lang="scss">
.ami-i {
  display: flex;
  align-items: center;
  font-size: var(--font-size-14);
  width: 100%;
  height: 100%;
  gap: 10px;

  &__icon {
    display: flex;
    justify-content: center;
    align-content: center;
    width: 24px;
  }

  &__label {
    flex: 1;
  }

  &__icon-font {
    &:before {
      font-family: 'Varus-Icons';
      content: var(--menu-font-icon);
      color: var(--orange);
      font-size: var(--menu-font-icon-size);
    }
  }

  &:focus {
    color: var(--orange-hover);
  }

  &__comment {
    color: var(--dark-grey, #757679);
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
}
</style>
