<template>
  <SfButton
    class="a-wishlist-icon sf-button--pure"
    role="button"
    aria-label="wishlist"
    data-transaction-name="Go To Wishlist"
    @click="goToAccountWishlist"
  >
    <span class="a-wishlist-icon__icon" :class="className"></span>

    <span
      v-cloak
      class="a-wishlist-icon__caption"
    >
      {{ $t("Wishlist") }}

      <template v-if="wishlistItemsLength">
        ({{ wishlistItemsLength }})
      </template>
    </span>
    <span
      v-cloak
      v-show="wishlistItemsLength"
      class="a-wishlist-icon__count"
    >
      {{ wishlistItemsLength }}
    </span>
  </SfButton>
</template>

<script>
import { SfButton } from '@storefront-ui/vue';
import {
  mapGetters,
  mapActions,
  mapMutations
} from 'vuex';
import { ModalList } from 'theme/store/ui/modals'

export default {
  name: 'AWishlistIcon',
  components: { SfButton },
  computed: {
    ...mapGetters({
      isLoggedIn: 'user/isLoggedIn',
      getWishlistItems: 'wishlist/getWishlistItems'
    }),
    className () {
      return {
        'a-wishlist-icon__icon--active': !!this.wishlistItemsLength
      }
    },
    wishlistItemsLength () {
      return this.getWishlistItems.length || 0
    }
  },
  methods: {
    ...mapActions({
      openModal: 'ui/openModal'
    }),
    ...mapMutations({
      closeMenu: 'ui/closeMenu'
    }),
    goToAccountWishlist () {
      const { isLoggedIn, $router, localizedRoute, openModal } = this;

      if (isLoggedIn) {
        $router.push(localizedRoute('/my-account/wishlist'))
        this.closeMenu()
        this.$store.dispatch('ui/hideBottomNavigation', false)
      } else {
        openModal({ name: ModalList.Auth, payload: 'auth' })
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@storefront-ui/shared/styles/helpers/breakpoints";
@import "~theme/css/fonts";

.a-wishlist-icon {
  &__caption {
    font-size: var(--font-sm);
    font-weight: normal;
    display: inline-flex;
    padding-left: var(--spacer-xs);
  }

  &__count {
    @include for-desktop {
      font-size: var(--font-size-14);
      font-weight: 500;
    }
  }

  &__label {
    display: none;
  }

  &__icon {
    font-size: 15.5px;
    @include font-icon(var(--icon-heart));
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--black);
    position: relative;
    margin-right: var(--spacer-4);
    margin-left: var(--spacer-2);

    &:hover {
      color: var(--orange-hover);
    }

    &--active {
      path {
        &:hover {
          fill: var(--orange-hover);
        }
      }

      .a-wishlist-icon__label {
        display: inline;
      }
    }
  }

  @include for-desktop {
    margin: 0 20px;

    &__caption {
      display: none;
    }
  }
}

.badge {
  position: absolute;
  bottom: 2.2em;
  left: 4.2em;
  font-size: 0.6em;
  padding: 0.3em 0;
  border-radius: 100%;
  width: 2.2em;
  min-height: 2.2em;
}
</style>
