<template>
  <component
    :is="linkComponent.tag"
    v-bind="linkComponent.bind"
    class="a-logo"
    :class="{ 'a-logo--responsive': isResponsive }"
    @click.native="closeMenuHandler"
  >
    <SfImage
      :src="logoPath"
      :alt="$t(defaultTitle)"
      class="a-logo__desktop"
      :lazy="false"
    />
    <img
      v-if="isResponsive"
      src="/assets/images/logo/logo-small.svg"
      :alt="$t(defaultTitle)"
      class="a-logo__mobile"
      loading="eager"
    />
  </component>
</template>

<script>
import config from 'config';
import { currentStoreView } from '@vue-storefront/core/lib/multistore';
import { SfImage } from '@storefront-ui/vue';
import { getLogoPath } from 'theme/helpers';
import { mapMutations, mapState } from 'vuex';
import { formatRegionUrl } from '$modules/shipping/utils/region-url';

export default {
  name: 'ALogo',
  components: { SfImage },
  props: {
    isPure: {
      type: Boolean,
      default: false
    },
    isResponsive: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      currentStore: null
    }
  },
  created () {
    this.currentStore = currentStoreView()
  },
  computed: {
    ...mapState({
      isMobileMenu: state => state.ui.isMobileMenu,
      varusConfig: state => state['config-varus'].config
    }),
    logoPath () {
      const logoConfig = this.varusConfig.find(item => item.config_path === 'header_logo_src');
      return logoConfig?.config_value ? getLogoPath(logoConfig.config_value) : '/assets/images/logo/logo.svg'
    },
    smallLogoPath () {
      const logoConfig = this.varusConfig.find(item => item.config_path === 'header_logo_src');
      return logoConfig?.config_value ? getLogoPath(logoConfig.config_value) : '/assets/images/logo/logo.svg'
    },
    defaultTitle () {
      return this.currentStore?.seo?.defaultTitle || config.seo.defaultTitle
    },
    linkComponent () {
      if (this.isPure) {
        return {
          tag: 'a',
          bind: {
            href: this.localizedRoute(formatRegionUrl('/')),
            title: this.$t('Home Page')
          }
        }
      }

      return {
        tag: 'router-link',
        bind: {
          to: this.localizedRoute(formatRegionUrl('/')),
          title: this.$t('Home Page')
        }
      }
    }
  },
  methods: {
    ...mapMutations({
      closeMenu: 'ui/closeMenu'
    }),
    closeMenuHandler () {
      if (!this.isMobileMenu) return

      this.closeMenu()
      this.$store.dispatch('ui/hideBottomNavigation', false)
    }
  }
};
</script>
<style  scoped lang="scss">
@import "~@storefront-ui/shared/styles/helpers/breakpoints";

.a-logo {
  display: inline-flex;
  flex: 0 0 auto;

  &__desktop {
    display: block;
    margin: 0;
    --image-width: 124px;
    --image-height: auto;

    @media only screen and (min-width: $tablet-min) {
      --image-width: 152px;
    }
  }

  &--responsive {
    padding: 6px;

    @media (min-width: $tablet-min) {
      padding: 0;
    }

    .a-logo__desktop {
      @media (max-width: $tablet-min - 1px) {
        display: none;
      }
    }

    .a-logo__mobile {
      display: block;
      width: 24px;
      height: 24px;
      @media (min-width: $tablet-min) {
        display: none;
      }
    }
  }
}
</style>
